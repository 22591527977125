<template>
  <div ref="swiperWrapper">
    <div class="container">
      <div
        class="flex flex-wrap items-center gap-x-vw-4-to-7 xl:mx-auto xl:w-10/12"
        :class="{
          'justify-end': !$slots.header,
          'justify-between': $slots.header,
        }"
        v-if="$slots.header || !hideNavigation || !hidePagination"
      >
        <slot name="header"></slot>
        <div v-if="!hideNavigation" class="flex gap-2 md:order-last">
          <button
            class="rounded-full transition-colors hover:text-blue focus:outline-none focus-visible:outline-current"
            ref="swiperButtonPrev"
            type="button"
          >
            <IconComponent
              class="size-vw-8-to-14"
              icon="circle-arrow-left-large"
            />
          </button>
          <button
            class="rounded-full transition-colors hover:text-blue focus:outline-none focus-visible:outline-current"
            ref="swiperButtonNext"
            type="button"
          >
            <IconComponent
              class="size-vw-8-to-14"
              icon="circle-arrow-right-large"
            />
          </button>
        </div>
        <div
          v-if="!hidePagination"
          class="mt-vw-6-to-7 flex justify-end max-md:w-full md:ml-auto md:mt-0"
          ref="swiperPagination"
        ></div>
      </div>
    </div>
    <swiper-container class="grid" init="false">
      <slot></slot>
    </swiper-container>
    <div class="container -mt-vw-8-to-9 flex justify-center md:mt-vw-6-to-7">
      <IconComponent
        class="size-vw-5-to-9 origin-bottom animate-wiggle"
        icon="touch-indicator"
      />
      <span class="sr-only">touch indicator</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'

const props = defineProps<{
  hideNavigation?: boolean
  hidePagination?: boolean
  slidesPerView?: string
  creativeEffectPrevTranslate?: [string, string, number]
  creativeEffectNextTranslate?: [string, string, number]
  loopSlides?: boolean
}>()

const swiperWrapper = ref()
const swiperButtonPrev = ref()
const swiperButtonNext = ref()
const swiperPagination = ref()

let swiperEl: any = null

onMounted(() => {
  swiperEl = swiperWrapper.value?.querySelector('swiper-container')
  if (swiperEl) {
    const swiperParams = {
      centeredSlides: true,
      initialSlide: 0,
      slidesPerView: getViewableSlides(),
      loop: swiperEl.childElementCount > 3 ? props.loopSlides : false,
      breakpoints: {
        768: {
          initialSlide: 1,
        },
      },
      ...(!props.hideNavigation
        ? {
            navigation: {
              prevEl: swiperButtonPrev.value,
              nextEl: swiperButtonNext.value,
              disabledClass: 'opacity-50 pointer-events-none',
            },
          }
        : {}),
      pagination: {
        el: swiperPagination.value,
        type: 'fraction',
      },
      effect: 'creative',
      creativeEffect: {
        prev: {
          rotate: [0, 0, -17],
          translate: props.creativeEffectPrevTranslate || ['-86.5%', '18%', 0],
        },
        next: {
          rotate: [0, 0, 17],
          translate: props.creativeEffectNextTranslate || ['86.5%', '18%', 0],
        },
      },
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
  }
  window.addEventListener('resize', updateHistorySwiper)
})

const updateHistorySwiper = useDebounceFn(() => {
  swiperEl.slidesPerView = getViewableSlides()
}, 100)

function getViewableSlides() {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
  let viewableSlides =
    ((18.12206572769953 * vw) / 100 + 3.065140845070423 * 16) / 100
  if (props.slidesPerView === 'variant1') {
    viewableSlides =
      ((13.427230046948358 * vw) / 100 + 7.915492957746478 * 16) / 100
  }
  return viewableSlides
}
</script>
